import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, ChangeDetectorRef, HostListener } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { forkJoin, Subject, Observable, Subscription } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { HomeComponent } from '../home/home.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { PrescriptionComponent } from '../modals/prescription/prescription.component';
import { CovidComponent } from '../configuration/covid/covid.component';
import { ViewFileComponent } from '../modals/view-file/view-file.component';
import { VitalsComponent } from '../modals/vitals/vitals.component';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from '../modals/image-preview/image-preview.component';
import { FileService } from 'src/app/shared/services/file.service';
import { Common, Avail } from 'src/app/common';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ChatMessageDto } from 'src/app/shared/models/user';
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { DatePipe, JsonPipe, LocationStrategy } from '@angular/common';
import { LabService } from 'src/app/shared/axaservices/lab.service';	
import { ApiService } from 'src/app/shared/services/api.service';	
import { MenuService } from 'src/app/shared/axaservices/menu.service';	
import { error } from 'protractor';

declare let $: any;
declare let stopVideo: any;
declare let stopVideoPocGiven: any;
declare let isPocGivenstopVideo: any;
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { ChatService } from 'src/app/shared/services/chat.service';
// import { ThrowStmt } from '@angular/compiler';
import { BsModalRef } from 'ngx-bootstrap';
import { ActiveDoctorsListComponent } from '../pro/consulatation/consulatation/active-doctors-list/active-doctors-list.component';
import { HttpErrorResponse } from '@angular/common/http';
// import { NewEmrComponent } from '../shared-emr/new-emr/new-emr.component';
import { PatientEmrComponent } from '../masters/laboratory-master/patient-emr/patient-emr.component';
import { HealthRiskEvaluationComponent } from '../masters/laboratory-master/health-risk-evaluation/health-risk-evaluation.component';
import { HrEvoluationComponent } from '../masters/laboratory-master/hr-evoluation/hr-evoluation.component';

@Component({
  selector: 'app-mvupoc-offline',
  templateUrl: './mvupoc-offline.component.html',
  styleUrls: ['./mvupoc-offline.component.css']
})
export class MvupocOfflineComponent implements OnInit {
  @ViewChild('scrollBottom') private scrollBottom: ElementRef;
  @ViewChild('search') searchElement: ElementRef;
  readonly imageTrigger: Subject<void> = new Subject<void>();	

pocForm1:FormGroup
private Subscription: Subscription;
minDate: Date;
tempErrMsg: boolean;
benHistory:any=[];
symptomsList:any
  dropdownSettings: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; enableCheckAll: boolean; itemsShowLimit: number; allowSearchFilter: boolean; };
  selectedItemsList:any=[];
  isPcChecked:boolean=false
  drug_list: any[];
  dN: any[];
  drugtypesList: any=[];
  drugNameSettings: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; enableCheckAll: boolean; allowSearchFilter: boolean; closeDropDownOnSelection:boolean};
  drugSettingsDrug: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; enableCheckAll: boolean; allowSearchFilter: boolean; };
  disableSearchByDrugName: any;
  drugId: any;
  drugnamesList:any=[];
  completeDrugList: any=[];
  submitted:boolean=false
  isFeature: any;
  medName: string;
  medType: string;
  selectedsearchItems: any;
  drugs_id: any;
  srhLst: any;
  pack_quantity: any;
  dT: any;
  searchDrug: string;
  searchDrugLst: any[];
  searchLst: any[];
  placeHolders = [];
  drugsData: any;
  nooftitle = '';
  Flagslist: any;
  pocpatientHistory: any;
  shwABHDetails: any;
  isEnableCovid19: any;
  isEnableAdviceAllField: any;
  hideOtherDrugLabel: boolean;
  enableConsultationTypeInPOC: any;
  enableInsuranceDetailsInPoc: any;
  enableProvisionalDiagnosis: any;
  showCreatedOn: any;
  HimsReferral: any;
  searchCheckedLst: any;
  tenantId: any;
  searchByDrugName: any;
  searchByDrugNameList: any = [];
  searchdrugdropdownSettings: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; closeDropDownOnSelection: boolean; itemsShowLimit: number; enableCheckAll: boolean; allowSearchFilter: boolean; defaultOpen: boolean; };
  searchDrugs: any;
  selectedItems: any=[];
  diagnosisList: any;
  selectedItemsDiagnosis: any=[];
  selectedDiagnosisList:any=[];
  selectedPrognosisList:any=[];
  dropdowndiagnosis: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; enableCheckAll: boolean; itemsShowLimit: number; allowSearchFilter: boolean; };
  prognosisList: any;
  selectedItemsPrognosis: any=[];
  dropdownPrognosis: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; enableCheckAll: boolean; itemsShowLimit: number; allowSearchFilter: boolean; };
  InvList: any;
  selectedItemsInvestigation: any=[];
  selectedInvList:any=[];
  dropdownInv: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; enableCheckAll: boolean; itemsShowLimit: number; allowSearchFilter: boolean; };
  bcsList: any;
  dewormingList: any;
  vaccinationList: any;
  enableDeworming: boolean;
  enableVac: boolean;
  DrugTypeList: any;

  selecteddrugList:any=[]
  tableData: any=[];
  tableRowForms:FormGroup;
  animalDetails: any;
  drugTypeId: any;
  drugNameId: any;
  administrationList: any;
  adminName: any;
  insuranceFilesList: any;
  dtRenderV: boolean=false;
  emergencyComment: any;
  dtRenderH: boolean=false;
  
  fcSG(gN: string) { return (<FormGroup>this.pocForm1.controls[gN]).controls };
  constructor(private el: ElementRef, private toastr: ToastrService,
    private locationData: LocationStrategy,	
    private labService:LabService
    , public homeComponent: HomeComponent
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService,
    private menuservice: MenuService
    , private masterService: MasterService
    , private ntfcnSvc: NotificationService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , public activeModal: NgbActiveModal
    , private authServ: AuthenticationService
    , private fileSvc: FileService
    , private datePipe: DatePipe
    , private router: Router
    , private userServ: UserService
    ,  public changeDetection: ChangeDetectorRef
    , private chatService: ChatService
    , private active: ActivatedRoute,) {

      this.active.queryParams.subscribe((res: any) => {
        this.animalDetails = JSON.parse( res.animalDetails);
      })
    this.Subscription = this.router.events
      .pipe(
        
        filter(
          (event: any) => {
            return (event instanceof NavigationStart);
          }
        )
      )
      .subscribe(
     
      )
      ;
    config.backdrop = 'static';
    config.keyboard = false;
    this.minDate = new Date();
   
    this.homeComponent.isDoctor = false;
    (<any>window).isPrescriptionSumitted = false;
    const content = document.body;
    content.classList.remove("sidenav-toggled");
    document.body.style.backgroundColor = "#fff";
   
    const footer = document.getElementById('footerHome');
    footer.setAttribute("style", "display:none !important");


  }
  ngOnInit() {
    
    this.getSymptoms();
    this.getTentativeDiagnosis();
    this.getPrognosis();
    this.getInvestigations();
    this.getBCS();
    this.getDeworming();
    this.getVaccination();
    this.drugtypes();
    this.initFormControls();
    this.getAdministrations();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'symptoms',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdowndiagnosis = {
      singleSelection: false,
      idField: 'id',
      textField: 'diagnosis_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownPrognosis = {
      singleSelection: true,
      idField: 'id',
      textField: 'prognosis',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownInv = {
      singleSelection: false,
      idField: 'id',
      textField: 'investigation_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.drugSettingsDrug = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    
  }

 


  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {

    var value: number = e.target['scrollingElement'].scrollTop
   
  }

  ngOnDestroy(): void {
    sessionStorage.isPatient = false;    
    this.Subscription.unsubscribe();
    this.imageTrigger.complete();
  }
 
  initFormControls() {
    this.pocForm1 = this.formBuilder.group({
     
      anamneasis: this.formBuilder.group({
       change_in_environment: [''],
        change_in_food: [''],
        deworming_status: [''],
        previous_illness: [''],
        previous_surgery: [''],
        previous_treated_with: [''],
        type_of_feeding:[''],
        vaccination_status: [''],
        deworming_status_other:[''],
        vaccination_status_other:['']
      }),
      animal_id: [''],
      call_type_id: [''],
      case_number: [''],

      clinical_observation : this.formBuilder.group({
        capillary_refill_time: [''],
        mucus_membrane: [''],
        pulse_rate: [''],
        respiratory_rate: [''],
        rumen_movements: [''],
        temparature: [''],
        stt:[''],
        bcs:['']
      }),

      comments: [''],
      consultation_follow_up: false,
      created_by: [''],
      diagnosis: {},
      doctor_id: [''],
      farmer_id: [''],
      follow_up_date: [''],
      id: 0,
      investigation: {},
      is_active: true,
      modified_by: 0,
      no_of_animals_effected: 0,
      no_of_animals_in_hard: 0,
      prescribed_medications: this.formBuilder.array([]),
      prognosis: {},
      referral_hospital_id: 0,
      symptoms: {},
      visit_date: null,
      visit_end_time: null,
      visit_start_time: null,
      withdrawal_period: this.formBuilder.group({
        meat_days:[''],
        milk_days:[''],
        remarks: [''],
        treatment_procedure:[''],
        treatment_provided: ['']
      }),
     
    });
    // this.addRow();
  }

  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;

        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].feature_id.feature_name == "doctorPocfilter") {
            this.pocpatientHistory = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "isNDHMEnable") {
            this.shwABHDetails = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "isEnableCovid19") {
            this.isEnableCovid19 = res.data[i].patient;
          }
          //Adive Field Enabled
          if (res.data[i].feature_id.feature_name == "isEnableAdviceAllField") {
            this.isEnableAdviceAllField = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "hideOtherDrugLabelinPOC") {
            if (res.data[i].patient) {
              this.hideOtherDrugLabel = true;
            } else {
              this.hideOtherDrugLabel = false;
            }

          }
          if (res.data[i].feature_id.feature_name == "enableConsultationTypeInPOC") {
            this.enableConsultationTypeInPOC = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "enableInsuranceDetailsInPoc") {
            this.enableInsuranceDetailsInPoc = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "enableProvisionalDiagnosis") {
            this.enableProvisionalDiagnosis = res.data[i].patient;
          }
          if (res.data[i].feature_id.feature_name == "consultationDate") {
            this.showCreatedOn = res.data[i].patient;
          } if (res.data[i].feature_id.feature_name == "disableSearchByDrugName") {
            this.disableSearchByDrugName = res.data[i].patient;
          } if (res.data[i].feature_id.feature_name == "HimsReferral") {
            this.HimsReferral = res.data[i].patient;
          }

        }
      }
    });
  }

  keyUp(elementId: string) {

    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    // console.log('el',el)
    el.value = el.value.replace(/^(0*)/, "");
    // console.log('  el.value',  el.value);

  }

  onTempChng(val: string) {
    this.keyUp('temperature'), this.tempErrMsg = false, sessionStorage.setItem('tempVldtn', '0');
    if (val != '') {
      if (val.includes('.')) {
        let [dotCnt, dcmlCnt] = [val.split('.'), val.split('.')];
        if (dotCnt.length > 0 || dcmlCnt.length > 0) {
          if ((dotCnt.length > 2 || dcmlCnt[1].length > 1) || (dotCnt.length > 0 && dcmlCnt[1].length == 0)) {
            this.tempErrMsg = true, sessionStorage.setItem('tempVldtn', '1');
            return;
          }
        }
      }
    }
  }

  onSelectAllMVU(item: any) {
    this.selectedItems = [];
    let list = [];
    list.push(this.symptomsList.map(item => item.id));
    this.selectedItems = list[0]
    console.log('Allserv', this.selectedItems);


  }

  onItemSelectMVU(item: any) {
    if (this.symptomsList.filter(f => f.id == item.id))
      this.selectedItems.push(item.id);

    this.selectedItems = [...this.selectedItems];
    console.log('selected item', this.selectedItems);


  }

  onDeSelectAllMVU(item: any) {
    this.selectedItems = [];
    console.log('AllDeserv', this.selectedItems);
  }

  onItemDeSelectMVU(item: any) {
    var data = this.selectedItems;
    data.forEach((x, i) => {
      if (x == item.id) data.splice(i, 1);
    });

    this.selectedItems = [...data];
    console.log('itemDeserv', this.selectedItems);

  }


  // Diagnosis dropdown

  onSelectAllDiagnosis(item: any) {
    this.selectedItemsDiagnosis = [];
    let list = [];
    list.push(this.diagnosisList.map(item => item.id));
    this.selectedItemsDiagnosis = list[0]
    console.log('Allserv', this.selectedItemsDiagnosis);


  }

  onItemSelectDiagnosis(item: any) {
    if (this.diagnosisList.filter(f => f.id == item.id))
      this.selectedItemsDiagnosis.push(item.id);

    this.selectedItemsDiagnosis = [...this.selectedItemsDiagnosis];
    console.log('selected item', this.selectedItemsDiagnosis);


  }

  onDeSelectAllDiagnosis(item: any) {
    this.selectedItemsDiagnosis = [];
    console.log('AllDeserv', this.selectedItemsDiagnosis);
  }

  onItemDeSelectDiagnosis(item: any) {
    var data = this.selectedItemsDiagnosis;
    data.forEach((x, i) => {
      if (x == item.id) data.splice(i, 1);
    });

    this.selectedItemsDiagnosis = [...data];
    console.log('itemDeserv', this.selectedItemsDiagnosis);

  }

  // Prognosis DropDown
  onSelectAllPrognosis(item: any) {
    this.selectedItemsPrognosis = [];
    let list = [];
    list.push(this.prognosisList.map(item => item.id));
    this.selectedItemsPrognosis = list[0]
    console.log('Allserv', this.selectedItemsPrognosis);


  }

  onItemSelectPrognosis(item: any) {
    if (this.prognosisList.filter(f => f.id == item.id))
      this.selectedItemsPrognosis.push(item.id);

    this.selectedItemsPrognosis = [...this.selectedItemsPrognosis];
    console.log('selected item', this.selectedItemsPrognosis);


  }

  onDeSelectAllPrognosis(item: any) {
    this.selectedItemsPrognosis = [];
    console.log('AllDeserv', this.selectedItemsPrognosis);
  }

  onItemDeSelectPrognosis(item: any) {
    var data = this.selectedItemsPrognosis;
    data.forEach((x, i) => {
      if (x == item.id) data.splice(i, 1);
    });

    this.selectedItemsPrognosis = [...data];
    console.log('itemDeserv', this.selectedItemsPrognosis);

  }

  // Investigation dropDown

  onSelectAllInve(item: any) {
    this.selectedItemsInvestigation = [];
    let list = [];
    list.push(this.InvList.map(item => item.id));
    this.selectedItemsInvestigation = list[0]
    console.log('Allserv', this.selectedItemsInvestigation);


  }

  onItemSelectInve(item: any) {
    if (this.InvList.filter(f => f.id == item.id))
      this.selectedItemsInvestigation.push(item.id);

    this.selectedItemsInvestigation = [...this.selectedItemsInvestigation];
    console.log('selected item', this.selectedItemsInvestigation);


  }

  onDeSelectAllInve(item: any) {
    this.selectedItemsInvestigation = [];
    console.log('AllDeserv', this.selectedItemsInvestigation);
  }

  onItemDeSelectInve(item: any) {
    var data = this.selectedItemsInvestigation;
    data.forEach((x, i) => {
      if (x == item.id) data.splice(i, 1);
    });

    this.selectedItemsInvestigation = [...data];
    console.log('itemDeserv', this.selectedItemsPrognosis);

  }


  // Drugs

  onDrgDeSlct(ctrl: string) {
    if (ctrl == 'dt')
      this.pocForm1.patchValue({ drugs: { drugType: '', drugName: '' } }), this.drug_list = [], this.drug_list = [...this.drug_list], this.dN = [];
    else
      this.pocForm1.patchValue({ drugs: { drugName: '' } });
  }

  onDrgSlct(item: any, ctrl: string) {
    if(ctrl == 'dt'){
      this.drugTypeId=item.id
      this.drugNames(item?.id)
    }else{
      this.drugNameId=item.id
    }
    
   
    this.drugNameSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection:false,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    
  }


  

  addRow1() {

    if(this.dN == null || this.dN == undefined || this.dN.length ==0){
      this.toastr.info("Please select Drug Name")
      return

    }
    
    // this.medName =  this.drug_list.find(e => e.id == this.dN[0].id).drug_name
    // this.medType =  this.drugtypesList.find(e => e.id == this.dT[0].id).drug_type
    // this.tableRowForm()
  
      let obj={
        "type": this.medType,
        "id": 0,
        "name":this.medName,
        "mrng": false,
        "aftr": false,
        "evng": false,
        "night": false,
        "sos": false,
        "dosage": '',
        "relfood": '',
        "period": '',
        "count": '',
        "remarks": '',
        "agentStock": "",
        "quantity": '',
        "instruction": ""
      }
    
   
    this.tableData.push(obj)
    console.log('this.tableData',this.tableData);
    
    this.dN=[];
    this.dT=[];
 
  }
  // deleteRow(index: number) {
  //   this.tableData.splice(index, 1);
  // }

  // get getRowFormControls() {
  //   const control = this.pocForm1.get('prescribed_medications') as FormArray;
  //   return control;
  // }

  // onRowDDChange(index, value) {
  //   this.nooftitle = 'No.of ' + value;
  //   this.placeHolders[index] = 'No.of ' + value;
  //   this.getRowFormControls.at(index).patchValue({ count: '' });
  //   if (value != '')
  //     this.getRowFormControls.at(index).get('count').enable();
  //   else
  //     this.getRowFormControls.at(index).get('count').disable();
  // }

  // tableRowForm(): FormGroup {
  //   return this.formBuilder.group({
  //     type: this.medType,
  //     id: this.drugs_id,
  //     name: this.medName,
  //     mrng: [false],
  //     aftr: [false],
  //     evng: [false],
  //     night: [false],
  //     sos: [false],
  //     dosage: ['', Validators.required],
  //     relfood: ['', Validators.required],
  //     period: ['', Validators.required],
  //     // count: [{ value: '', disabled: true }, Validators.required],
  //     count: [{ value: '', disabled: false }, Validators.required],
  //     remarks: [''],
  //     instruction: [''],
  //     agentStock: [''],
  //   });
  // }

  // onValChng(index: number, val: any, ctrl: string) {
  //   if (val != '') {
  //     if (+val == 0 && ctrl == 'dosage')
  //       this.getRowFormControls.at(index).patchValue({ dosage: '' });
  //     if (+val == 0 && ctrl == 'count')
  //       this.getRowFormControls.at(index).patchValue({ count: '' });
  //   }
  // }
  // viewAgentStock() {
  //   //this.formStockData = this.pocForm.value;
  //   for (let i = 0; i < this.pocForm1.value.prescribed_medications.length; i++) {
  //     this.pocForm1.value.prescribed_medications[i].name = this.pocForm1.value.prescribed_medications[i].name.toUpperCase();
  //     //  this.prescriptionData.prescribed_medications[i]['cost'] = '';

  //     for (let j = 0; j < this.drugsData.length; j++) {
  //       if (this.drugsData[j].drug_name != undefined) {
  //         this.drugsData[j].drug_name = this.drugsData[j].drug_name.toUpperCase();
  //       }
  //       if (this.pocForm1.value.prescribed_medications[i].name == this.drugsData[j].drug_name) {
  //         this.pocForm1.value.prescribed_medications[i].agentStock = this.drugsData[j].stock;
  //         let newDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  //         let expDate = this.datePipe.transform(this.drugsData[j].expiry_date, "yyyy-MM-dd");
  //         if (expDate <= newDate) {
  //           this.getRowFormControls.at(i).patchValue({ agentStock: 0 });
  //         } else {
  //           this.getRowFormControls.at(i).patchValue({ agentStock: this.drugsData[j].stock });
  //         }

  //       }
  //     }
  //   }
  
  // }

  // onCheckbox(index) {
  //   let c = this.getRowFormControls.at(index);
  //   if (c.get('sos').value == true) {
  //     // this.isSosChecked = true;
  //     c.get('mrng').setValue(false);
  //     c.get('aftr').setValue(false);
  //     c.get('evng').setValue(false);
  //     c.get('night').setValue(false);
  //     c.get('relfood').setValue('Anytime');

  //   }
  //   else {
  //     // this.isSosChecked = false;
  //     c.get('sos').setValue(false);
  //     c.get('relfood').setValue('');



  //   }

  //   if (this.submitted && (c.get('mrng').value || c.get('aftr').value || c.get('evng').value || c.get('night').value || c.get('sos').value))
  //     c.get('mrng').setErrors(null);
  // }

  onSelectdrug(item: any, type: string) {
    if (type == 's') {
      this.searchCheckedLst = item;
    }
    else if (type == 'd') {
      this.selectedsearchItems.filter(item => item !== item);
      this.searchCheckedLst = this.selectedsearchItems;
    }
  }

  onDrugSelect() {
    let drugtype = 'medicine';
    this.tenantId = sessionStorage.getItem('currentTenant');
    if (this.searchDrug?.length > 2) {
      this.searchdrugdropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'drug_name',
        selectAllText: 'Select All',
        unSelectAllText: 'Deselect All',
        closeDropDownOnSelection: true,
        itemsShowLimit: 10,
        enableCheckAll: false,
        allowSearchFilter: true,
        defaultOpen: true
      };

      this.registrationService.getDrugName(`poc-emr/jwt/doctor/poc/searchmedicine?name=${this.searchDrug}&type=${drugtype}&category=allopathy&TENANT_ID=${this.tenantId}`).subscribe(res => {
        if (res) {
          this.searchDrugLst = res.data;
          this.searchDrugLst.forEach(f => {
            this.searchLst.push({
              id: f.drug_id?.id,
              drug_name: f.drug_id?.drug_name,
              pack_quantity: f.drug_id?.pack_quantity,
              type: f.drug_type_id.drug_type
            })
          })
          this.srhLst = this.searchLst;
          // console.log(this.srhLst);
        }
      }, err => { this.toastr.error("No data found") }

      )

    }


  }

  searchDrugName() {
    // document.getElementById('openOnEvent').click();
    if (this.searchDrugs) {
      let drId = JSON.parse(sessionStorage.getItem('DoctorDetails'))?.id ?? null;
      if (this.searchByDrugName.length > 2) {
        this.drugNameSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'drug_name',
          selectAllText: 'Select All',
          unSelectAllText: 'Deselect All',
          closeDropDownOnSelection: true,
          itemsShowLimit: 1,
          enableCheckAll: false,
          allowSearchFilter: true
        };

        // this.LoadDrugsbySearchDTId(this.drugId);
    
      }
    
    } else {
      if (this.searchByDrugName.length < 3) {

      }
    }
  }


  // Api Start

  getSymptoms(){

    this.registrationService.get(`mvu/animalsymptoms/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.symptomsList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  getTentativeDiagnosis(){

    this.registrationService.get(`mvu/animaldiagnosis/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.diagnosisList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  getPrognosis(){

    this.registrationService.get(`mvu/animalprognosis/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.prognosisList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  getInvestigations(){

    this.registrationService.get(`mvu/animalinvestigation/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.InvList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  getBCS(){

    this.registrationService.get(`mvu/animalbcs/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.bcsList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  getDeworming(){

    this.registrationService.get(`mvu/animaldewormingstatus/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.dewormingList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  getVaccination(){

    this.registrationService.get(`mvu/vaccinationstatus/getallbyactive`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.vaccinationList = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

  dewText(value:any){
  if(value==3){
    this.enableDeworming=true
  }else{
    this.enableDeworming=false
  }
  }

  vacText(value:any){
    if(value==3){
      this.enableVac=true
    }else{
      this.enableVac=false
    }
    }

    drugtypes(){
      this.registrationService.get(`mvu/drug_types/getbyinventorytypeid/1`).subscribe({
        next: res => {
          if (res.status == 'OK') {
             this.drugtypesList = res.data??[]
          //  this.toastr.success(res.message)
  
          } else {
            let errMsg: string = res.message;
            this.toastr.error(errMsg);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.toastr.error(err.message);
  
        },
        complete: () => {
          // Any additional logic after patching values
        },
      });
    }

    drugNames(value:any){
      this.registrationService.get(`mvu/drugs/getbydrugtypeid/${value}`).subscribe({
        next: res => {
          if (res.status == 'OK') {
             this.drug_list = res.data??[]
          //  this.toastr.success(res.message)
  
          } else {
            let errMsg: string = res.message;
            this.toastr.error(errMsg);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.toastr.error(err.message);
  
        },
        complete: () => {
          // Any additional logic after patching values
        },
      });
    }

   onSubMit(){
    let doctorDetails=JSON.parse(sessionStorage.getItem('DoctorDetails'))

    let payload={
      anamneasis: this.pocForm1.value.anamneasis,
       animal_id:  this.animalDetails?.animal_id,
       call_type_id:this.animalDetails?.call_type_id,
       case_number: this.animalDetails?.case_number ,
       clinical_observation : this.pocForm1.value. clinical_observation,
       comments: this.pocForm1.value.comments,
       consultation_follow_up: this.pocForm1.value.consultation_follow_up,
       created_by: sessionStorage.loginId,
       diagnosis: {
        diagnosis: this.selectedDiagnosisList
       },
       doctor_id: doctorDetails.id,
       farmer_id: this.animalDetails?.farmer_id,
       follow_up_date: this.pocForm1.value.follow_up_date,
       id: 0,
       investigation: {
        investigation: this.selectedInvList
       },
       is_active: true,
       modified_by: 0,
       no_of_animals_effected:this.pocForm1.value.no_of_animals_effected,
       no_of_animals_in_hard: this.pocForm1.value.no_of_animals_in_hard,
       prescribed_medications: {
        prescribed_medications:this.pocForm1.value.prescribed_medications
       },
       prognosis: {
        prognosis: this.selectedPrognosisList
       },
       referral_hospital_id: 0,
       symptoms: {
        symptoms: this.selectedItemsList,
       },
       visit_date:this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm:ss'),
       visit_end_time: null,
       visit_start_time: null,
       withdrawal_period: this.pocForm1.value.withdrawal_period,
       referred_doctor_id :null,
       vlda_id:null
      
    }

    // const payload = this.pocForm1.getRawValue();
    // payload.prognosis=this.selectedPrognosisList
    // payload.diagnosis=this.selectedDiagnosisList
    // payload.investigation=this.selectedInvList
    // payload.symptoms=this.selectedItemsList
    //  payload.animal_id= this.animalDetails?.animal_id,
    // payload.call_type_id= this.animalDetails?.call_type_id,
    //  payload.case_number=this.animalDetails?.case_number ,
    //  payload.created_by= sessionStorage.loginId,
    //  payload.farmer_id=this.animalDetails?.farmer_id,
    //  payload.doctor_id=doctorDetails?.id,
    //  payload.prescribed_medications=this.tableData
    //  payload.visit_date=this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm:ss')
      console.log('payload',payload);
    this.registrationService.post(`mvu/poc/save`,payload).subscribe({
      next: res => {
        if (res.status == 'OK') {
           let saveData = res.data??[]
          this.toastr.success(res.message)
          this.changeMenu();

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
   }

   gotoConsultation(){
    this.initFormControls();
    this.Subscription.unsubscribe();
    this.imageTrigger.complete();	
    let onlyFirstTime: boolean = (<any>window).isPrescriptionSumitted;
    (<any>window).isPrescriptionSumitted = true;
    this.changeMenu();
    this.toastr.success('Consultation is Cancelled');
      
      }
      

      changeMenu() {
        this.homeComponent.isDoctor = true;
        const value = {
          screen_id: 72,
          module_id: 6
        }
        this.router.navigate(['/home/consultation/cn']).then(r => {
          setTimeout(() => {
            this.homeComponent.sidemenu(value, 'another')
          }, 100);
        })
    
    
      }

      createMedication(): FormGroup {
       
        return this.formBuilder.group({
          type: this.medType,
          id: this.drugNameId,
          name: this.medName,
          mrng: false,
          aftr: false,
          evng: false,
          night: false,
          sos: false,
          dosage: '',
          relfood: '',
          period: '',
          count: '',
          remarks: '',
          agentStock: '',
          // quantity: '',
          instruction: '',
          administration_id:'',
          administration:this.adminName
        });
      }
      
      // Function to add a medication to the form array
      addRow() {
        if(this.dN == null || this.dN == undefined || this.dN.length ==0){
          this.toastr.info("Please select Drug Name")
          return
    
        }
         this.medName = ''; this.medType = '';
          if (this.drug_list == undefined) {
            this.drug_list = [];
          }
          if (this.drug_list.length > 0) {
            this.medName = this.drug_list.find(e => e.id == this.drugNameId).drug_name ;
            this.medType = this.drugtypesList.find(e => e.id == this.drugTypeId)?.drug_type;
            this.drug_list=[];
    
          }
        (this.pocForm1.get('prescribed_medications') as FormArray).push(this.createMedication());


        this.dN=[];
        this.dT=[];

        this.medName='';
        this.medType='';
        // this.adminName='';
      }
      
      // Function to remove a medication from the form array
      deleteRow(index: number) {
        (this.pocForm1.get('prescribed_medications') as FormArray).removeAt(index);
      }

      
  keyPressNumbers(event: any) {
    var inp = event.key;
    if (/[0-9%.]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaNumeric(event: any) {
    var inp = event.key;
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphabets(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  getAdministrations(){

    this.registrationService.get(`mvu/root-of-administration/getall`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.administrationList = res.data??[]
         this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

}

onSelectedAdmin(value:any){

// this.adminName = this.administrationList.find( (e: any) => e.id == value).root_of_administration;


}

openPath(document:any){
  let webURL = location.href.includes('localhost') ? environment.applicationURL : location.origin;

  let documentpath= webURL+document
  //  Common.showlabpharmaPDF_File(documentpath);
   window.open(documentpath,'_blank',);
}



openViewFileModal(){
$('#viewFiles').modal('show')
this.dtRenderV=false
this.registrationService.get(`mvu/animals/getcosultationsummarydocument/${this.animalDetails?.id}`).subscribe({
  next: res => {
    if (res.status == 'OK') {
       this.insuranceFilesList = res.data??[]
       this.dtRenderV=true
     this.toastr.success(res.message)

    } else {
      let errMsg: string = res.message;
      this.toastr.error(errMsg);
      this.dtRenderV=true
    }
  },
  error: (err: HttpErrorResponse) => {
    this.toastr.error(err.message);
    this.dtRenderV=true

  },
  complete: () => {
    // Any additional logic after patching values
  },
}); 
}

farmerHistory(){
  $('#history').modal('show')
  this.dtRenderH=false
  this.registrationService.get(`mvu/poc/getconsultationlistbyanimalid/${this.animalDetails?.animal_id}`).subscribe({
    next: res => {
      if (res.status == 'OK') {
         this.insuranceFilesList = res.data??[]
         this.dtRenderH=true
       this.toastr.success(res.message)
  
      } else {
        let errMsg: string = res.message;
        this.toastr.error(errMsg);
        this.dtRenderH=true
      }
    },
    error: (err: HttpErrorResponse) => {
      this.toastr.error(err.message);
      this.dtRenderH=true
  
    },
    complete: () => {
      // Any additional logic after patching values
    },
  }); 

  }

emergencyExit() {
  this.pocForm1.patchValue({ comments:this.emergencyComment });
  this.onSubMit();
}

}
